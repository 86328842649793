import React, { useEffect, useState, useRef } from 'react';
import { FwTabs, FwTab, FwTabPanel } from '@freshworks/crayons/react';
import OrderSearch from './OrderSearch';
import OrderListItem from "./OrderListItem";
import OrderSkeleton from './utils/OrderSkeleton';
import { extractOrder } from './utils/OrderIdExtractor';
import { detectOrderNumbers } from './utils/OrderNumberDetector';
import { getOrderReferences, getOrderTypeName, getOrderEmail, getCountryCode } from './utils/MappingHelper';
import { useTranslation } from 'react-i18next';
import useFetchReferencesList from '../hooks/useFetchReferencesList';
import usePlentyDataFetcher from '../hooks/usePlentyDataFetcher';


const OrderManager = ({ client }) => {
    const { t } = useTranslation();
    const [ordersList, setOrdersList] = useState([]);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [assignedOrderId, setAssignedOrderId] = useState(null);
    const [initialSearchParameters, setInitialSearchParametersState] = useState({orderId: '', externalOrderId: '', addressData: '', orderIds: ''});
    const [ticket, setTicket] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [orderReferrers, setOrderReferrers] = useState([]);
    const [webstores, setWebstores] = useState([]);
    const [shippingProfiles, setShippingProfiles] = useState([]);
    const tabsRef = useRef();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [extractedOrderSearch, setExtractedOrderSearch] = useState({orderFound: false, orderId: null});
    const [referencesList, setReferencesList] = useState([]);
    const [referencedOrdersList, setReferencedOrdersList] = useState([]);
    const [isOrderDetectionRunning, setIsOrderDetectionRunning] = useState(false);


    const getCookie = () => {
        const value = "; " + document.cookie;
        const parts = value.split("; " + "zenCookie" + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    const fetchOrders = async (searchParameters, setOrdersList, setCurrentOrder, currentOrder, client) => {
        try {
            // return;
            setIsLoading(true);
            setOrdersList([]);
            
            console.log("INVOKE SEARCH WITH:", searchParameters);


            // New ZAF Request
            // const eventRequestOptions = {
            //     url: `/api/v2/users/12345676543456754/events`,
            //     data: `page[size]=5`
            //   }

            //'settings.plentyToken' 
            const zafSettings = await client.zafClient.metadata();

            console.log("ZAF SETTINGS", zafSettings.settings);

            
            ////////////
            // const payloadData = { action: "getAllOrders", data: searchParameters };
            // const options = {
            //     url: "https://892d-2003-6-33ae-d445-453e-a24b-4065-45d7.ngrok-free.app/invokeAction",
            //     type: "POST",
            //     headers: {
            //       Authorization: "zenCookie=" + getCookie(),
            //       "x-plenty-user": "{{setting.plentyUserName}}",
            //       "x-plenty-pw": "{{setting.plentyPW}}",
            //       "x-plenty-host": "{{setting.plentyHost}}",
            //       Accept: "application/json",
            //       //"ngrok-skip-browser-warning": "69420",
            //     },
            //     contentType: 'application/x-www-form-urlencoded',
            //     data: JSON.stringify(payloadData),
            //     cors: false,
            //     secure: true,
            //   };
              
            //   const allOrdersResponse = await client.zafClient.request(options).then((response) => {
            //     return response;
            //   }, (error) => {
            //     throw error.responseJSON;
            //   });
              /////////

            //Original
            const allOrdersResponse = await client.request.invoke('getAllOrders', searchParameters);


            console.log('OrderManager - Response for all orders is', allOrdersResponse.response.entries);
            
            // Store fetched orders in a local variable
            const fetchedOrdersList = allOrdersResponse.response.entries || [];

            setOrdersList(fetchedOrdersList); 
            setIsLoading(false);
            return fetchedOrdersList;
        } catch (error) {
            setIsLoading(false);
            console.error("Unable to fetch orders XXXX:", error);
            await client.interface.trigger("showNotify", { type: "danger", message: error.message || 'Something went wrong!' });
        }
    };

    //useEffect for referencesList
    useFetchReferencesList(referencesList, currentOrder, client, getOrderReferences, setIsLoadingDetail, setReferencedOrdersList);
  

    // const getPlentyAuth = async () => {
    //     try {
    //         await client.request.invoke('getPlentyAuth', {});
    //     } catch (error) {
    //         console.error("Unable to get plenty auth", error);
    //     }
    // };


    


    const handleOrderUpdate = (updatedOrder) => {
        console.log("OrderManager - Updated order is", updatedOrder);
        setOrdersList(ordersList => ordersList.map(order => 
            order.id === updatedOrder.id ? updatedOrder : order
        ));
        if (currentOrder && currentOrder.id === updatedOrder.id) {
            setCurrentOrder(updatedOrder);
        }
    };

    const getPlentyOrderById = async (orderId) => {
        try {
            setCurrentOrder(null);
            setReferencedOrdersList([]);
            setReferencesList([]);
            setIsLoadingDetail(true);
            const orderData = await client.request.invoke('getPlentyOrderById', { orderId });
            setCurrentOrder(orderData.response);
            console.log("OrderManager - Order data is", orderData);
            setIsLoadingDetail(false);
            return orderData.response;
        } catch (error) {
            setIsLoadingDetail(false);
            console.error("Unable to get order data", error);
            return null;
        }
    };

    const getPlentyOrderByExternalId = async (searchParameters) => {
        try {
            setCurrentOrder(null);
            setReferencedOrdersList([]);
            setReferencesList([]);
            setIsLoadingDetail(true);
            const orderData = await client.request.invoke('getAllOrders', searchParameters);
            const fetchedOrdersList = orderData.response.entries || [];
            if (fetchedOrdersList.length > 0) {
                setActiveTabIndex(1);
                setCurrentOrder(fetchedOrdersList[0]);
                setIsLoadingDetail(false);
                return fetchedOrdersList[0];
            }
            setIsLoadingDetail(false);
            return null;
        } catch (error) {
            setIsLoadingDetail(false);
            await client.interface.trigger("showNotify", { type: "danger", message: error.message || 'Something went wrong!' });
            return null;
        }
    };



    // Function to invoke the assignOrder server function
    const handleOnAssignOrder = async (orderId) => {
        try {
            await client.request.invoke('assignOrder', { ticketId: ticket.id, orderId });
            console.log("Order assigned successfully");

            
            await client.interface.trigger("showNotify", { type: "success", message: t("order_assigned_success") });

            setActiveTabIndex(1);
            try {
                const orderData = await getPlentyOrderById(orderId);
                if (orderData) {
                    setAssignedOrderId(orderId);

                    await setCustomTicketFields(orderData, false);

                }
            } catch (error) {
                console.error("Unable to get order data or order not found", error);
            }
            
        } catch (error) {
            await client.interface.trigger("showNotify", { type: "danger", message: error.message || 'Something went wrong!' });
            console.error("Failed to assign order", error);
        }
    };


    const setCustomTicketFields = async (orderData, clearFields) => {
        
        const zafSettings = await client.zafClient.metadata();
        console.log("Setting custom fields for ticket.", zafSettings.settings);
        console.log("Setting custom fields for ticket. ORDER:", orderData);

        
        // {
        //     "fieldCountCollis": "20317880078482",
        //     "fieldItemId": "20317836024082",
        //     "fieldOrderType": null,
        //     "fieldEmail": null,
        //     "fieldExternalVariantsId": null,
        //     "fieldLager": null,
        //     "fieldLieferadresseFirmenname": null,
        //     "fieldLieferadresseLand": null,
        //     "fieldLieferadresseNachname": null,
        //     "fieldLieferadressePlz": null,
        //     "fieldLieferadresseStadt": null,
        //     "fieldLieferadresseStrasse": null,
        //     "fieldLieferadresseVorname": null,
        //     "fieldLieferadresseZusatz": null,
        //     "fieldModelNumber": null,
        //     "fieldPlentyOrderId": null,
        //     "fieldSalesChannel": null,
        //     "fieldSendungsnummer": null,
        //     "fieldSendungsverfolgung": null,
        //     "fieldSKU": null,
        //     "fieldStatus": null,
        //     "fieldVariantenId": null,
        //     "fieldVariantenname": null,
        //     "fieldVersandprofil": null,
        //     "fieldVornameKunde": null
        // } xxxxxxxxx

        const address = (orderData && orderData.addresses) && orderData.addresses.find(a => a.pivot.typeId === 2);
        const invoiceAddress = (orderData && orderData.addresses) && orderData.addresses.find(a => a.pivot.typeId === 1);

        let orderReferrerName = null;
        if( orderData) {
            const currentOrderReferrer = orderReferrers.find(referrer => parseFloat(referrer.id) === parseFloat(orderData.referrerId));
            orderReferrerName = currentOrderReferrer ? currentOrderReferrer.backendName : null;
        }

        console.log("DEBUG - ADDRESS", address);


        let customFieldsValues = {
            "fieldCountCollis": clearFields ? null : orderData.shippingPackages.length,
            "fieldItemId": clearFields ? null :  orderData.orderItems.filter(item => item.typeId !== 6).map(item => item.id).join(","),
            "fieldOrderType": clearFields ? null : t(getOrderTypeName(orderData.typeId)),
            "fieldEmail": clearFields ? null : getOrderEmail(orderData),
            "fieldExternalVariantsId": clearFields ? null : orderData.orderItems.filter(item => item.typeId !== 6).map(item => item.variation ? item.variation.externalId : '').join(", "),
            "fieldLager": clearFields ? null : orderData.warehouseSender ? orderData.warehouseSender.name : null,
            "fieldLieferadresseFirmenname": clearFields ? null : address ? address.name1 : null,
            "fieldLieferadresseLand": clearFields ? null : address ? getCountryCode(address.countryId) : null,
            "fieldLieferadresseNachname": clearFields ? null : address ? address.name3 : null,
            "fieldLieferadressePlz": clearFields ? null : address ? address.postalCode : null,
            "fieldLieferadresseStadt": clearFields ? null : address ? address.town : null,
            "fieldLieferadresseStrasse": clearFields ? null : address ? address.address1 : null,
            "fieldLieferadresseVorname": clearFields ? null : address ? address.name2 : null,
            "fieldLieferadresseZusatz": clearFields ? null : getFormattedAddress(address),
            "fieldModelNumber": clearFields ? null : orderData.orderItems.filter(item => item.typeId !== 6).map(item => item.variation ? item.variation.model : '').join(", "), 
            "fieldPlentyOrderId": clearFields ? null : orderData.id,
            "fieldSalesChannel": clearFields ? null : orderReferrerName,
            "fieldSendungsnummer": clearFields ? null : orderData.shippingPackages.map(shippingPackage => shippingPackage.packageNumber).join(", "),
            "fieldSendungsverfolgung": clearFields ? null : createTrackingLinkCombined(orderData),
            "fieldSKU": clearFields ? null : orderData.orderItems.filter(item => item.typeId !== 6).map(item => item.variation ? item.variation.number : '').join(", "),
            "fieldStatus": clearFields ? null : orderData.statusName ? orderData.statusName : null,
            "fieldVariantenId": clearFields ? null : orderData.orderItems.filter(item => item.typeId !== 6).map(item => item.variation ? item.variation.id : '').join(", "),
            "fieldVariantenname": clearFields ? null : orderData.orderItems.filter(item => item.typeId !== 6).map(item => item.variation ? item.variation.name : '').join(", "),
            "fieldVersandprofil": clearFields ? null : getShippingProfileName(orderData),
            "fieldVornameKunde": clearFields ? null : invoiceAddress ? invoiceAddress.name2 : null,
        };




        // Create the update custom fields payload by finding the custom field IDs from the settings
        const customFields = {};
        for (const [key, value] of Object.entries(customFieldsValues)) {
            const customFieldId = zafSettings.settings[key];
            if (customFieldId) {
                customFields[`ticket.customField:custom_field_${customFieldId}`] = value;
            }
        }

        // Set the custom fields for the ticket
        try {
            await client.zafClient.set(customFields);
            console.log("Custom fields updated successfully.");
        } catch (error) {
            console.error("Error updating custom fields:", error);
        }

        console.log("Setting custom fields for ticket. Update Fields Payload:", customFields);

        console.log("Setting custom fields for ticket. Field Values:", customFieldsValues);

     };

     const getFormattedAddress = (address) => {
        if (!address) return null;
        const { address2, address3, address4 } = address;
        const addressParts = [address2, address3, address4].filter(part => part && part.trim() !== '');
        return addressParts.join(' ');
      };

     const createTrackingLinkCombined = (orderData) => {

        const shippingPackagesList = orderData.shippingPackages;
        console.log("TESTXYZ1", shippingPackagesList);
        if(!shippingPackagesList || shippingPackagesList.length === 0) return null;

        const packageNumbers = shippingPackagesList.map((shippingPackage) => shippingPackage.packageNumber).join(',');

        const shippingProfileId = getPropertyValue(orderData, 2);
        console.log("TESTXYZ2", shippingProfileId);
        if(!shippingProfileId) return null;
        const shippingProfile = shippingProfiles && shippingProfiles.find(sp => sp.id == shippingProfileId);
        console.log("TESTXYZ3", shippingProfile, shippingProfiles);
        if(!shippingProfile) return null;
        const trackingUrl = shippingProfile.trackingUrl;
        if(!trackingUrl) return null;

        const deliveryAddress = orderData.addresses && orderData.addresses.find(a => a.pivot.typeId === 2);
        if(!deliveryAddress) return null;

        let trackingUrlWithParams = trackingUrl.replace('[PaketNr]', packageNumbers);

        if(deliveryAddress.postalCode) {
            trackingUrlWithParams = trackingUrlWithParams.replace('[PLZ]', deliveryAddress.postalCode);
        }

        return trackingUrlWithParams;

    }

     const getShippingProfileName = (order) => {
        const shippingProfileId = getPropertyValue(order, 2);
        if(!shippingProfileId) return '-';
        const shippingProfile = shippingProfiles && shippingProfiles.find(sp => sp.id == shippingProfileId);
        return shippingProfile ? shippingProfile.name : '-';
    }

    const getPropertyValue = (order, typeId) => {
        const property = order.properties && order.properties.find(p => p.typeId === typeId);
        return property ? property.value : null;
    }

    const handleOnUnAssignOrder = async (orderId) => {
        try {
            await client.request.invoke('unAssignOrder', { ticketId: ticket.id, orderId });
            console.log("Order unassigned successfully");
            await client.interface.trigger("showNotify", { type: "success", message: t("order_unassigned_success") });
            setAssignedOrderId(null);
            await setCustomTicketFields(null, true);
        } catch (error) {
            await client.interface.trigger("showNotify", { type: "danger", message: error.message || 'Something went wrong!' });
            console.error("Failed to unassign order", error);
        }
    };
    

    const getAssignedOrderId = async (ticket) => {
        try {
            const assignedOrderId = await client.request.invoke('getAssignedOrder', { ticketId: ticket.id });
            //console.log("Assigned order id is", assignedOrderId.response.orderId);
            if (assignedOrderId.response && assignedOrderId.response.orderId) {
                setAssignedOrderId(assignedOrderId.response.orderId);
                return assignedOrderId.response.orderId;
            }
            return null;
        } catch (error) {
            await client.interface.trigger("showNotify", { type: "danger", message: error.message || 'Something went wrong!' });
            console.error("Failed to get assigned order", error);
        }
    };

    const getCurrentTicket = async () => {
        try {
            //const ticketData = await client.data.get("ticket");
            const ticketData = await client.zafClient.get("ticket");

            console.log("ZAF Ticket data", ticketData);

            setTicket(ticketData.ticket);
            console.log("OrderManager - Ticket data is", ticketData.ticket);
            return ticketData.ticket;
        } catch (error) {
            await client.interface.trigger("showNotify", { type: "danger", message: error.message || 'Something went wrong!' });
            console.error("Unable to get ticket data", error);
        }
    };

    const handleSearch = async (searchParameters) => {
        console.log('Search initiated with parameters: ', searchParameters);
        const ordersList = await fetchOrders(searchParameters, setOrdersList, setCurrentOrder, currentOrder, client);
        return ordersList;
    };

 

    const getPlentyOrderReferrers = async () => {
        try {
            const referrersData = await client.request.invoke('getPlentyOrderReferrers', {});
            console.log("Referrers data is", referrersData);
            setOrderReferrers(referrersData.response.orderReferrers);
            setWebstores(referrersData.response.webstores);
        } catch (error) {
            await client.interface.trigger("showNotify", { type: "danger", message: error.message || 'Something went wrong!' });
            console.error("Unable to get plenty order referrers", error);
        }
    };


    const getPlentyStatuses = async () => {
        try {
            const statusesData = await client.request.invoke('getPlentyStatuses', {});
            setStatuses(statusesData.response.orderStatuses);
        } catch (error) {
            await client.interface.trigger("showNotify", { type: "danger", message: error.message || 'Something went wrong!' });
            console.error("Unable to get plenty statuses", error);
        }
    };

    const getPlentyShippingProfiles = async () => {
        try {
            const shippingProfilesData = await client.request.invoke('getPlentyShippingProfiles', {});
            console.log("Shipping profiles data is", shippingProfilesData);
            setShippingProfiles(shippingProfilesData.response.shippingProfiles);
        } catch (error) {
            await client.interface.trigger("showNotify", { type: "danger", message: error.message || 'Something went wrong!' });
            console.error("Unable to get plenty shipping profiles", error);
        }
    };

    const generateInitialSearchParameters = async () => {
        console.log("OrderManager - Setting initial search parameters");
        try {

            const contactTicket = await client.zafClient.get(['ticket.requester']);

            console.log("ZAF Contact data", contactTicket['ticket.requester']);

            //const contactTicket = await client.data.get("contact");

            console.log("OrderManager - Contact data is", contactTicket['ticket.requester']);
            setInitialSearchParametersState({
                ...initialSearchParameters,
                addressData: contactTicket['ticket.requester'].email,
            });
            return {
                ...initialSearchParameters,
                addressData: contactTicket['ticket.requester'].email,
            };

        } catch (error) {
            await client.interface.trigger("showNotify", { type: "danger", message: error.message || 'Something went wrong!' });
            console.error("Unable to set initial search parameters", error);
        }
    };

    useEffect(() => {
        setTimeout(() => {
        if (tabsRef.current && tabsRef.current.shadowRoot) {
            const shadowRoot = tabsRef.current.shadowRoot;
            console.log('shadowRoot for Tabs Ref', shadowRoot);
            const style = document.createElement('style');
            style.textContent = '.tabs__items__nav { padding-inline-start: 0px !important; }';
            shadowRoot.appendChild(style);
        }
        }, 100);
    }, []); 

    const handleActiveTabChange = (event) => {
        console.log("OrderManager - Active tab changed to", event.detail.tabIndex);
        setActiveTabIndex(event.detail.tabIndex);
    };

    const handleOnViewOrder = async (orderId) => {
        console.log("OrderManager - View order clicked for order id", orderId);
        setActiveTabIndex(1);
        await getPlentyOrderById(orderId);
    };

    // useEffect(() => {
    //     if (extractedOrderSearch.orderFound && !assignedOrderId) {
    //         console.log("OrderManager - Extracted order is", extractedOrderSearch);

    //         const foundOrder = await getPlentyOrderById(orderNumber);
    //         console.log("OrderDetection - Found order is", foundOrder);
    //         if(foundOrder) {
    //             setActiveTabIndex(1);
    //         }

    //         handleOnAssignOrder(extractedOrderSearch.orderId);
    //     }
    // }, [extractedOrderSearch]);

    useEffect(() => {
        if (extractedOrderSearch.orderFound && !assignedOrderId) {
            console.log("OrderManager - Extracted order is", extractedOrderSearch);
    
            const fetchOrder = async () => {
                const foundOrder = await getPlentyOrderById(extractedOrderSearch.orderId);
                console.log("OrderDetection - Found order is", foundOrder);
                
                if(foundOrder) {
                    setActiveTabIndex(1);
                }
                
                handleOnAssignOrder(extractedOrderSearch.orderId);
            };
    
            fetchOrder();
        }
    }, [extractedOrderSearch]);
    

    const handleOnSetReferencesList = (referencesList) => {
        setReferencesList(referencesList);
    };


    // usePlentyDataFetcher(
    //     client,
    //     getPlentyAuth,
    //     getPlentyStatuses,
    //     getPlentyOrderReferrers,
    //     getPlentyShippingProfiles,
    //     getCurrentTicket,
    //     getAssignedOrderId,
    //     setActiveTabIndex,
    //     getPlentyOrderById,
    //     generateInitialSearchParameters,
    //     handleSearch,
    //     extractOrder,
    //     setExtractedOrderSearch,
    //     detectOrderNumbers,
    //     getPlentyOrderByExternalId
    //   );
    const setCurrentClient = usePlentyDataFetcher(client, {
        // getPlentyAuth,
        getPlentyStatuses,
        getPlentyOrderReferrers,
        getPlentyShippingProfiles,
        getCurrentTicket,
        getAssignedOrderId,
        setActiveTabIndex,
        getPlentyOrderById,
        generateInitialSearchParameters,
        handleSearch,
        extractOrder,
        setExtractedOrderSearch,
        detectOrderNumbers,
        getPlentyOrderByExternalId,
        setIsOrderDetectionRunning,
    });

    // Custom hook to fetch data from an API
    // const useFetchData = (client) => {
    //     useEffect(() => {
    //         // Abort controller for cleaning up async requests if the component is unmounted.
    //         const abortController = new AbortController();

    //         const fetchData = async () => {
    //             try {
    //                 // Authenticate
    //                 await getPlentyAuth();

    //                 // Fetch statuses (no await because we don't need it to be resolved before the next step)
    //                 getPlentyStatuses();
    //                 getPlentyOrderReferrers();
    //                 getPlentyShippingProfiles();

    //                 // Fetch current ticket
    //                 const ticket = await getCurrentTicket();

    //                 // Fetch assigned order using ticket
    //                 const assignedOrderId = await getAssignedOrderId(ticket);

    //                 // Dont run search if there is an assigned order
    //                 if (assignedOrderId) {
                      
    //                     //Switch tab to "detail"
    //                     setActiveTabIndex(1);
    //                     await getPlentyOrderById(assignedOrderId);

    //                 }

    //                 // Set initial search parameters and execute search
    //                 const searchParameters = await generateInitialSearchParameters();
    //                 const ordersList = await handleSearch(searchParameters);

    //                 if(ordersList.length > 0 && !assignedOrderId) {
    //                     const {orderFound, orderId} = extractOrder(ordersList, ticket.subject, ticket.description);
    //                     setExtractedOrderSearch({orderFound, orderId});
    //                     console.log("ORDER EXTRACTION RESULT:", orderFound, orderId);
    //                 }

    //                 // If ordersList is empty and there is no assigned order, perform a detectOrderNumbers in ticket
    //                 if(ordersList.length === 0 && !assignedOrderId) {
    //                     console.log("OrderDetection - No orders found in search, trying to detect order number in ticket");
    //                     const {orderNumberFound, orderNumber, isOrderId, isExternalOrderId} = detectOrderNumbers(ticket.subject, ticket.description);
    //                     console.log("OrderDetection - Order Detection result:", orderNumberFound, orderNumber, isOrderId, isExternalOrderId);
    //                     //if orderNumberFound and isOrderId, search for order by id
    //                     if(orderNumberFound && isOrderId) {
    //                         console.log("OrderDetection - Order number found in ticket, searching for order by id");
    //                         const foundOrder = await getPlentyOrderById(orderNumber);
    //                         console.log("OrderDetection - Found order is", foundOrder);
    //                         if(foundOrder) {
    //                             setActiveTabIndex(1);
    //                         }
    //                     }
    //                     //if orderNumberFound and isExternalOrderId, search for order by externalOrderId
    //                     if(orderNumberFound && isExternalOrderId) {
    //                         console.log("OrderDetection - Order number found in ticket, searching for order by externalOrderId");
    //                         const searchParameters = {externalOrderId: orderNumber};
    //                         const foundOrder = await getPlentyOrderByExternalId(searchParameters);
    //                         console.log("OrderDetection - Found order is", foundOrder);
    //                         if(foundOrder) {
    //                             setActiveTabIndex(1);
    //                         }
    //                     }
    //                     console.log("OrderDetection - No order number found in ticket");
    //                 }
                                    
                    

    //             } catch (error) {
    //                 console.error('An error occurred while fetching data:', error);
    //             }
    //         };

    //         // Execute the fetchData function
    //         fetchData();

    //         // Cleanup logic
    //         return () => {
    //             abortController.abort();
    //         };
    //     }, [client]); 
    // };

    // useFetchData(client);


    return (
        <div data-test-id="app-container">
            <FwTabs activeTabIndex={activeTabIndex} onFwChange={handleActiveTabChange} ref={tabsRef}>
            

       
                <FwTab slot="tab" panel="search">{t('tab_title_order_search')}</FwTab>
                <FwTab slot="tab" panel="detail">{t('tab_title_order_details')}</FwTab>
   
                

                <div className="pt-4">
                <FwTabPanel name="search">
                    <OrderSearch onSearch={handleSearch} initialSearchParameters={initialSearchParameters} />

                    {isLoading && (
                         <OrderSkeleton isDetail="false" />
                    )}
                    
                    <div>
                    {ordersList.map((order) => (
                        <OrderListItem
                        mode="search"
                        key={order.id}
                        order={order}
                        client={client}
                        statuses={statuses}
                        orderReferrers={orderReferrers}
                        webstores={webstores}
                        shippingProfiles={shippingProfiles}
                        onOrderUpdate={handleOrderUpdate}
                        onViewOrder={handleOnViewOrder}
                        onAssignOrder={handleOnAssignOrder}
                        onUnAssignOrder={handleOnUnAssignOrder}
                        assignedOrderId={assignedOrderId}
                        />
                    ))}
                    </div> 

                    {ordersList.length === 0 && !isLoading && (
                        <div className="mt-4 mb-3">
                            <h2 className="text-xs text-center font-semibold text-gray-400 uppercase" data-test-id="no_orders_found">{t("no_orders_found")}</h2>
                        </div>
                   )}  

                     {ordersList.length === 0 && isOrderDetectionRunning && (
                        <div className="mt-10 mb-3">
                            <h2 className="text-xs text-center font-semibold text-gray-400 uppercase animate-pulse" data-test-id="order_detection_running">{t("order_detection_running")}</h2>
                        </div>
                     )}



                </FwTabPanel>
                </div>

                <FwTabPanel name="detail">
                    
                    {currentOrder && (
                        <OrderListItem 
                        mode="detail"
                        key={currentOrder.id} 
                        order={currentOrder} 
                        statuses={statuses}
                        orderReferrers={orderReferrers}
                        webstores={webstores} 
                        shippingProfiles={shippingProfiles}
                        client={client} 
                        onOrderUpdate={handleOrderUpdate} 
                        onViewOrder={handleOnViewOrder}
                        onAssignOrder={handleOnAssignOrder}
                        onUnAssignOrder={handleOnUnAssignOrder}
                        onSetReferencesList={handleOnSetReferencesList}
                        assignedOrderId={assignedOrderId}
                        />
                    )}

                    {!currentOrder && !isLoadingDetail && (
                        <div className="mt-4 mb-3">
                            <h2 className="text-xs text-center font-semibold text-gray-400 uppercase" data-test-id="no_order_selected">{t("no_order_selected")}</h2>
                        </div>
                   )} 

                    {/* referencedOrdersList */}
                    {referencesList.length > 0 && (
                        <div className="mt-4 mb-3">
                            <h2 className="text-xs text-center font-semibold text-gray-400 uppercase">{t("referenced_oders")}</h2>
                        </div>
                    )}

                    <div>
                    {referencedOrdersList.map((order) => (
                        <OrderListItem
                        mode="referenced"
                        key={order.id}
                        order={order}
                        client={client}
                        statuses={statuses}
                        orderReferrers={orderReferrers}
                        webstores={webstores}
                        shippingProfiles={shippingProfiles}
                        onOrderUpdate={handleOrderUpdate}
                        onViewOrder={handleOnViewOrder}
                        onAssignOrder={handleOnAssignOrder}
                        onUnAssignOrder={handleOnUnAssignOrder}
                        assignedOrderId={assignedOrderId}
                        />
                    ))}
                    </div>  
                    {/*  /. referencedOrdersList */}  
                    
                    {isLoadingDetail && (
                         <OrderSkeleton isDetail="true" />
                    )}

                       

                </FwTabPanel>
            </FwTabs>

        </div>
    );
};

export default OrderManager;
