export default class ExtendedZAFClient {
  constructor() {
      this.zafClient = window.ZAFClient.init();
      console.log('ZAFClient initialized X', this.zafClient);




      this.interface = {
          trigger: this.triggerInterface.bind(this)
      };
      this.request = {
          invoke: this.invokeRequest.bind(this)
      };
  }

  invoke(method, message, type) {
      this.zafClient.invoke(method, message, type);
  }

  async triggerInterface(method, options) {
      switch (method) {
          case 'showNotify':

              
              // type = 'info' if options.type is success, type = 'error' if options.type is danger
              const type = (options.type === 'success' ? 'notice' : 'error')
              const message = options.message || 'Something went wrong!';
              this.invoke('notify', `${message}`, `${type}`);
              break;
          default:
              throw new Error(`Unknown method: ${method}`);
      }
  }

  getCookie() {
      const value = "; " + document.cookie;
      const parts = value.split("; " + "zenCookie" + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
  }

  async invokeRequest(action, data) {
      const payloadData = {
          action,
          data
      };

      // Get and log all ticket fields
        // this.zafClient.get('ticket.customField:custom_field_20309587458194').then(function(data) {
        //     console.log("Custom fields:", data);
        // }).catch(function(error) {
        //     console.error("Error getting custom fields:", error);
        // });


    //   const customFields = {
    //     "ticket.customField:custom_field_20309587458194": "value1",
    //     "ticket.customField:custom_field_20309587715346": "value2"
    //     };
        
    //     this.zafClient.set(customFields).then(function() {
    //         console.log("Custom fields updated successfully.");
    //     }).catch(function(error) {
    //         console.error("Error updating custom fields:", error);
    //     });

      const options = {
            //url: "https://if3md3-ip-104-151-44-110.tunnelmole.net/invokeAction",
          url: "https://mokebo-zendesk-app.apoio-dev.com/invokeAction",
          type: "POST",
          headers: {
              Authorization: "zenCookie=" + this.getCookie(),
              "x-plenty-user": "{{setting.plentyUserName}}",
              "x-plenty-pw": "{{setting.plentyPW}}",
              "x-plenty-host": "{{setting.plentyHost}}",
              Accept: "application/json",
             //"ngrok-skip-browser-warning": "69420", // remove this line in production
          },
          contentType: 'application/x-www-form-urlencoded',
          data: JSON.stringify(payloadData),
          cors: false,
          secure: true
      };

      return this.zafClient.request(options).then((response) => {
          return response;
      }, (error) => {
          throw error.responseJSON;
      });
  }
}
